var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "resultSection"
  }, [_c('h3', [_vm._v("Welcome, " + _vm._s(_vm.username) + "!")]), _c('p', [_vm._v("Right now on Reach, you could earn up to")]), _c('h1', [_c('b', [_vm._v("$")]), _vm._v(_vm._s(_vm.numberMagnitudeString(_vm.maxPossibleEarnings)))]), _c('h4', [_vm._v("Download Reach & Get Started Now!")]), _c('AppBadges', _vm._b({}, 'AppBadges', {
    brandCampaignId: _vm.brandCampaignId
  }, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }