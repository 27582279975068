var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    staticClass: "publicMediaKitPreview themeListItem themeExtraPadding"
  }, 'div', {
    isMobile: _vm.isMobile
  }, false), [_c('div', {
    staticClass: "creatorInfo"
  }, [_c('UserProfilePhoto', {
    staticClass: "mediaKitImg",
    attrs: {
      "user": _vm.mediaKit,
      "forceMediumQuality": ""
    }
  }), _c('div', {
    staticClass: "creatorMainSection"
  }, [_c('div', {
    staticClass: "creatorTopLine"
  }, [_c('h3', [_vm._v(_vm._s(_vm.mediaKit.fullName))]), _vm.mediaKit.location ? _c('div', {
    staticClass: "creatorLocation"
  }, [_c('MapPinIcon', {
    attrs: {
      "color": "#000"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.mediaKit.location))])], 1) : _vm._e()]), _c('div', {
    staticClass: "creatorAccountList"
  }, _vm._l(_vm.fullAccountList, function (accountItem, i) {
    return _c('div', {
      key: i,
      staticClass: "creatorAccount themeListItem"
    }, [_c('div', {
      staticClass: "creatorAccountTopLine"
    }, [_vm.getPlatformIcon(accountItem.account.platform) ? _c(_vm.getPlatformIcon(accountItem.account.platform), {
      tag: "component",
      staticClass: "platformIcon",
      class: {
        creatorAccountIconLarge: !_vm.hasMetrics(accountItem)
      },
      attrs: {
        "color": "#000"
      }
    }) : _vm._e(), _c('p', {
      staticClass: "creatorAccountName",
      class: {
        creatorAccountNameLarge: !_vm.hasMetrics(accountItem)
      }
    }, [accountItem.account.platform === _vm.YOUTUBE_NATIVE && accountItem.account.displayName ? [_vm._v(_vm._s(accountItem.account.displayName))] : accountItem.account.username ? [_vm._v("@" + _vm._s(accountItem.account.username))] : accountItem.account.displayName ? [_vm._v(_vm._s(accountItem.account.displayName))] : _vm._e()], 2)], 1), _vm.hasMetrics(accountItem) ? _c('div', {
      staticClass: "creatorAccountMetrics"
    }, _vm._l(_vm.ACCOUNT_METRICS_TO_DISPLAY, function (_ref) {
      var key = _ref.key,
          label = _ref.label,
          get = _ref.get;
      return _c('div', {
        key: key,
        staticClass: "metric"
      }, [get(accountItem) !== undefined ? [_c('p', [_vm._v(_vm._s(label))]), _c('p', [_vm._v(_vm._s(_vm.numberMagnitudeString(get(accountItem))))])] : _vm._e()], 2);
    }), 0) : _vm._e()]);
  }), 0)])], 1), _c('div', {
    staticClass: "creatorRateSection"
  }, [!_vm.isMobile ? _c('p', [_vm._v("click for more info")]) : _vm._e(), _vm.shouldShowRate ? _c('div', {
    staticClass: "creatorRate"
  }, [_c('p', [_vm._v("Rate")]), _c('p', [_vm._v(_vm._s(_vm.deliverableRateString))])]) : _vm._e(), _vm.isMobile ? _c('p', [_vm._v("tap for more info")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }