/* eslint-disable no-param-reassign */

export default {
  twoWay: true,
  bind(el, { expression }, { context, tag }) {
    el.resizeObservers = el.resizeObservers || {}
    el.resizeObservers[tag] = new ResizeObserver((entries) => {
      let newValue
      if (entries.length) {
        const { contentRect, borderBoxSize } = entries[entries.length - 1]
        const { top, left, right, bottom } = contentRect || {}
        if (borderBoxSize && borderBoxSize.length) {
          const [dimensions] = borderBoxSize
          const width = dimensions.inlineSize
          const height = dimensions.blockSize
          newValue = { width, height, top, left, right, bottom }
        } else if (contentRect) {
          const { width, height } = contentRect
          newValue = { width, height, top, left, right, bottom }
        }
      }
      if (newValue) {
        context[expression] = newValue
      }
    })
    el.resizeObservers[tag].observe(el)
  },
  update(el, _, { tag }, { tag: oldTag }) {
    if (tag !== oldTag) {
      el.resizeObservers[tag] = el.resizeObservers[oldTag]
      delete el.resizeObservers[oldTag]
    }
  },
  unbind(el, _, { tag }) {
    el.resizeObservers[tag].disconnect()
    delete el.resizeObservers[tag]
  },
}
