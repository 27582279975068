var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "viewBox": "0 0 15 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-outside-1",
      "maskUnits": "userSpaceOnUse",
      "x": "-0.414214",
      "y": "-4.14893",
      "width": "15.5563",
      "height": "15.5563",
      "fill": _vm.color
    }
  }, [_c('rect', {
    attrs: {
      "fill": _vm.color,
      "x": "-0.414214",
      "y": "-4.14893",
      "width": "15.5563",
      "height": "15.5563"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.61985 4.4236C1.27814 4.76531 1.27814 5.31933 1.61985 5.66104L5.29375 9.33494C5.30549 9.34819 5.3177 9.36116 5.33039 9.37384C5.56143 9.60489 5.88953 9.67971 6.18345 9.59832C6.32488 9.55947 6.45847 9.48448 6.5696 9.37335C6.57914 9.36381 6.58842 9.3541 6.59743 9.34423L13.3737 2.56794C13.7154 2.22623 13.7154 1.67221 13.3737 1.3305C13.032 0.988794 12.478 0.988794 12.1363 1.3305L5.95024 7.51655L2.85729 4.4236C2.51558 4.0819 1.96156 4.0819 1.61985 4.4236Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.61985 4.4236C1.27814 4.76531 1.27814 5.31933 1.61985 5.66104L5.29375 9.33494C5.30549 9.34819 5.3177 9.36116 5.33039 9.37384C5.56143 9.60489 5.88953 9.67971 6.18345 9.59832C6.32488 9.55947 6.45847 9.48448 6.5696 9.37335C6.57914 9.36381 6.58842 9.3541 6.59743 9.34423L13.3737 2.56794C13.7154 2.22623 13.7154 1.67221 13.3737 1.3305C13.032 0.988794 12.478 0.988794 12.1363 1.3305L5.95024 7.51655L2.85729 4.4236C2.51558 4.0819 1.96156 4.0819 1.61985 4.4236Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M5.29375 9.33494L5.66792 9.00328L5.65794 8.99202L5.6473 8.98139L5.29375 9.33494ZM6.18345 9.59832L6.05102 9.11617L6.05 9.11645L6.18345 9.59832ZM6.59743 9.34423L6.24388 8.99068L6.23588 8.99868L6.22824 9.00704L6.59743 9.34423ZM5.95024 7.51655L5.59668 7.87011L5.95024 8.22366L6.30379 7.87011L5.95024 7.51655ZM1.97341 5.30749C1.82696 5.16104 1.82696 4.9236 1.97341 4.77716L1.2663 4.07005C0.729328 4.60702 0.729328 5.47762 1.2663 6.01459L1.97341 5.30749ZM5.6473 8.98139L1.97341 5.30749L1.2663 6.01459L4.9402 9.68849L5.6473 8.98139ZM5.68394 9.02029C5.67835 9.0147 5.67301 9.00903 5.66792 9.00328L4.91958 9.6666C4.93797 9.68734 4.95706 9.70762 4.97683 9.7274L5.68394 9.02029ZM6.05 9.11645C5.9226 9.15174 5.7825 9.11885 5.68394 9.02029L4.97683 9.7274C5.34036 10.0909 5.85647 10.2077 6.31689 10.0802L6.05 9.11645ZM6.21605 9.0198C6.16782 9.06802 6.11105 9.09969 6.05102 9.11617L6.31587 10.0805C6.53872 10.0193 6.74911 9.90095 6.92315 9.72691L6.21605 9.0198ZM6.22824 9.00704C6.22432 9.01134 6.22025 9.01559 6.21605 9.0198L6.92315 9.72691C6.93804 9.71202 6.95253 9.69686 6.96662 9.68142L6.22824 9.00704ZM13.0202 2.21439L6.24388 8.99068L6.95099 9.69779L13.7273 2.92149L13.0202 2.21439ZM13.0202 1.68406C13.1666 1.8305 13.1666 2.06794 13.0202 2.21439L13.7273 2.92149C14.2642 2.38452 14.2642 1.51392 13.7273 0.976949L13.0202 1.68406ZM12.4898 1.68406C12.6363 1.53761 12.8737 1.53761 13.0202 1.68406L13.7273 0.976949C13.1903 0.439979 12.3197 0.439978 11.7827 0.976949L12.4898 1.68406ZM6.30379 7.87011L12.4898 1.68406L11.7827 0.976949L5.59668 7.163L6.30379 7.87011ZM2.50374 4.77716L5.59668 7.87011L6.30379 7.163L3.21084 4.07005L2.50374 4.77716ZM1.97341 4.77716C2.11985 4.63071 2.35729 4.63071 2.50374 4.77716L3.21084 4.07005C2.67387 3.53308 1.80327 3.53308 1.2663 4.07005L1.97341 4.77716Z",
      "fill": _vm.color,
      "mask": "url(#path-1-outside-1)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }