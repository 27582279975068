//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InlineMenu',
  props: {
    bottom: Boolean,
    left: Boolean,
    center: Boolean,
    minWidth: {
      type: String,
      default: '100px',
    },
    maxWidth: {
      type: String,
      default: '400px',
    },
    backgroundColor: {
      type: String,
      default: '#2E2F37',
    },
    show: {
      type: Boolean,
      default: true,
    },
    showTriangle: Boolean,
  },
}
