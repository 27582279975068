//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import crypto from 'crypto'

export default {
  name: 'LoadingSpinner',
  props: {
    type: String, // primary, secondary, greenAccent, redAccent, blueAccent, orangeAccent, greyscaleDark, greyscaleLight
    defaultDimensions: {
      type: Boolean,
      default: true,
    },
    small: Boolean,
    circled: Boolean,
  },
  data() {
    return {
      elementId: crypto.randomBytes(16).toString('hex'),
    }
  },
  computed: {
    colors() {
      switch (this.type) {
        case 'primary':
          return {
            darkest: '#8F00FF',
            lightest: '#0085FF',
          }
        case 'secondary':
          return {
            darkest: '#FF0000',
            lightest: '#FF7500',
          }
        case 'yellowAccent':
          return {
            darkest: '#f0c200',
            lightest: '#fde301',
          }
        case 'greenAccent':
          return {
            darkest: '#007e1f',
            lightest: '#01FD3F',
          }
        case 'redAccent':
          return {
            darkest: '#AE0C4D',
            lightest: '#FF1272',
          }
        case 'blueAccent':
          return {
            darkest: '#0076FF',
            lightest: '#1BFFFF',
          }
        case 'orangeAccent':
          return {
            darkest: '#E04800',
            lightest: '#FF6A14',
          }
        case 'tiktok':
          return {
            darkest: '#FF0050',
            lightest: '#ff7fa7',
          }
        case 'greyscaleDark':
          return {
            darkest: '#000',
            lightest: '#494B58',
          }
        case 'greyscaleMiddle':
          return {
            darkest: '#494B58',
            lightest: '#7D7E88',
          }
        case 'greyscaleLight':
          return {
            darkest: '#7D7E88',
            lightest: '#B1B2B7',
          }
        case 'greyscaleLightest':
        default:
          return {
            darkest: '#7D7E88',
            lightest: '#FFF',
          }
      }
    },
    loadingSpinnerStyle() {
      const result = {}

      const { defaultDimensions, small } = this
      if (defaultDimensions) {
        result.width = small ? '30px' : '50px'
        result.height = small ? '30px' : '50px'
      }

      return result
    },
  },
}
