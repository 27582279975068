var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlayManager",
    class: {
      currentToast: _vm.currentToast
    }
  }, [_c('transition', [_vm.currentToast ? _c(_vm.currentToast.component, _vm._b({
    key: _vm.currentToast.showTime || _vm.currentToast.key,
    tag: "component",
    staticClass: "toast",
    style: {
      top: _vm.currentToast.top || "".concat(-_vm.headerTranslation + _vm.TOP_NAV_HEIGHT, "px"),
      zIndex: _vm.currentToast.zIndex
    },
    nativeOn: {
      "click": function click($event) {
        $event.stopPropagation();
        return function () {}($event);
      },
      "mouseenter": function mouseenter($event) {
        return _vm.toastMouseEnter($event);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.toastMouseLeave($event);
      }
    }
  }, 'component', _vm.currentToast.props, false)) : _vm._e()], 1), _c('transition-group', {
    attrs: {
      "tag": "div",
      "duration": 300
    }
  }, _vm._l(_vm.fullscreenPopups, function (popup, i) {
    return _c('PopupLayer', _vm._b({
      key: popup.id,
      staticClass: "popupLayer",
      style: {
        top: popup.belowTopNav ? "".concat(_vm.TOP_NAV_HEIGHT - _vm.headerTranslation, "px") : 0
      },
      on: {
        "close": function close($event) {
          return _vm.REMOVE_FULLSCREEN_POPUP_AT_INDEX(i);
        }
      }
    }, 'PopupLayer', popup, false));
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }