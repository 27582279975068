var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "videoPreviewContainer clickable",
    on: {
      "click": _vm.toggleStatus
    }
  }, [!_vm.videoPlaying ? _c('div', {
    staticClass: "iconOverlay"
  }, [_c('PlayIcon', {
    staticStyle: {
      "height": "24px",
      "width": "24px"
    }
  })], 1) : _vm._e(), _c('video', {
    ref: "previewVideo",
    attrs: {
      "src": _vm.src + '#t=0.001',
      "type": _vm.type,
      "playsinline": "",
      "preload": "metadata"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }