import { render, staticRenderFns } from "./UserProfilePhoto.vue?vue&type=template&id=34e11416&scoped=true&"
import script from "./UserProfilePhoto.vue?vue&type=script&lang=js&"
export * from "./UserProfilePhoto.vue?vue&type=script&lang=js&"
import style0 from "./UserProfilePhoto.vue?vue&type=style&index=0&id=34e11416&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e11416",
  null
  
)

export default component.exports