//
//
//
//
//
//

import Gradient from '@/components/universal/basic/Gradient.vue'

export default {
  name: 'SwitchInput',
  components: {
    Gradient,
  },
  props: {
    value: Boolean,
    disabled: Boolean,
  },
}
