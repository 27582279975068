//
//
//
//
//
//

export default {
  name: 'GenericSocialIcon',
  props: {
    color: {
      type: String,
      default: 'var(--white)',
    },
  },
}
