//
//
//
//
//
//

export default {
  name: 'Gradient',
  props: {
    type: {
      type: String,
      default: 'primary', // primary, secondary, greenAccent, redAccent, blueAccent, orangeAccent, greyscaleDarker, greyscaleDark, greyscaleMiddle, greyscaleLight, greyscaleLighter
    },
    colorWithinBorder: String,
  },
  computed: {
    gradientStyle() {
      const { type, colorWithinBorder } = this

      let darkColor = '#8F00FF'
      let lightColor = '#0085FF'

      switch (type) {
        case 'secondary':
          darkColor = '#FF0000'
          lightColor = '#FF7500'
          break
        case 'yellowAccent':
          darkColor = '#f0c200'
          lightColor = '#fde301'
          break
        case 'greenAccent':
          darkColor = '#007e1f'
          lightColor = '#01FD3F'
          break
        case 'redAccent':
          darkColor = '#AE0C4D'
          lightColor = '#FF1272'
          break
        case 'blueAccent':
          darkColor = '#0076FF'
          lightColor = '#1BFFFF'
          break
        case 'orangeAccent':
          darkColor = '#E04800'
          lightColor = '#FF6A14'
          break
        case 'greyscaleDarker':
          darkColor = '#1B1C21'
          lightColor = '#2E2F37'
          break
        case 'greyscaleDark':
          darkColor = '#2E2F37'
          lightColor = '#373842'
          break
        case 'greyscaleMiddle':
          darkColor = '#494B58'
          lightColor = '#636570'
          break
        case 'greyscaleLight':
          darkColor = '#7D7E88'
          lightColor = '#9798A0'
          break
        case 'greyscaleLighter':
          darkColor = '#B1B2B7'
          lightColor = '#CBCCCF'
          break
        case 'greyscaleLightest':
          darkColor = '#E5E5E7'
          lightColor = '#FFF'
          break
        case 'error':
          darkColor = '#FF1272'
          lightColor = '#FF1272'
          break
        default:
          break
      }

      const gradientBackground = `linear-gradient(5deg, ${darkColor}, ${lightColor})`

      return {
        background: `${colorWithinBorder ? `linear-gradient(${colorWithinBorder}, ${colorWithinBorder}) padding-box, ` : ''}${gradientBackground}${colorWithinBorder ? ' border-box' : ''}`,
      }
    },
  },
}
