//
//
//
//
//
//
//
//
//
//
//

import BrandCampaignVideoPreview from '@/components/brands/BrandCampaignVideoPreview.vue'

export default {
  name: 'BrandCampaignVideoPreviewList',
  props: {
    titleText: {
      type: String,
      default: 'Currently Active Paid Campaigns',
    },
    campaignList: {
      type: Array,
      default: () => [],
    },
    listProcessing: {
      type: Function,
      default: (arr) => arr,
    },
  },
  components: {
    BrandCampaignVideoPreview,
  },
  computed: {
    processedCampaignList() {
      return this.listProcessing(this.campaignList)
    },
  },
}
