var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InputFrame', _vm._b({
    class: {
      hasPrefix: _vm.prefix
    }
  }, 'InputFrame', {
    defaultDimensions: _vm.defaultDimensions && !_vm.multiline,
    disabled: _vm.disabled,
    error: _vm.error,
    useGenericBackdropGradient: _vm.useGenericBackdropGradient
  }, false), [_vm.prefix ? _c('p', {
    attrs: {
      "id": "prefix"
    }
  }, [_vm._v(_vm._s(_vm.prefix))]) : _vm._e(), _vm.multiline ? _c('textarea', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "input",
    class: {
      bold: _vm.boldValue && _vm.model.length,
      defaultDimensions: _vm.defaultDimensions
    },
    attrs: {
      "type": _vm.obscureValues ? 'password' : _vm.inputType
    },
    domProps: {
      "value": _vm.model
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('returnKey');
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.model = $event.target.value;
      }
    }
  }, 'textarea', Object.assign({
    disabled: _vm.disabled,
    placeholder: _vm.placeholder,
    autocomplete: _vm.autocomplete,
    maxlength: _vm.maxlength
  }, _vm.inputAttributes), false)) : (_vm.obscureValues ? 'password' : _vm.inputType) === 'checkbox' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "input",
    class: {
      bold: _vm.boldValue && _vm.model.length
    },
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.model) ? _vm._i(_vm.model, null) > -1 : _vm.model
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('returnKey');
      },
      "change": function change($event) {
        var $$a = _vm.model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.model = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.model = $$c;
        }
      }
    }
  }, 'input', Object.assign({
    disabled: _vm.disabled,
    placeholder: _vm.placeholder,
    autocomplete: _vm.autocomplete,
    maxlength: _vm.maxlength
  }, _vm.inputAttributes), false)) : (_vm.obscureValues ? 'password' : _vm.inputType) === 'radio' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "input",
    class: {
      bold: _vm.boldValue && _vm.model.length
    },
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.model, null)
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('returnKey');
      },
      "change": function change($event) {
        _vm.model = null;
      }
    }
  }, 'input', Object.assign({
    disabled: _vm.disabled,
    placeholder: _vm.placeholder,
    autocomplete: _vm.autocomplete,
    maxlength: _vm.maxlength
  }, _vm.inputAttributes), false)) : _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "input",
    class: {
      bold: _vm.boldValue && _vm.model.length
    },
    attrs: {
      "type": _vm.obscureValues ? 'password' : _vm.inputType
    },
    domProps: {
      "value": _vm.model
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('returnKey');
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.model = $event.target.value;
      }
    }
  }, 'input', Object.assign({
    disabled: _vm.disabled,
    placeholder: _vm.placeholder,
    autocomplete: _vm.autocomplete,
    maxlength: _vm.maxlength
  }, _vm.inputAttributes), false)), _c('transition', [_vm.checkingValue ? _c('LoadingSpinner', {
    attrs: {
      "defaultDimensions": false,
      "id": "checking"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }