//
//
//
//
//
//

import { mapState } from 'vuex'
import Gradient from '@/components/universal/basic/Gradient.vue'

export default {
  name: 'InputFrame',
  components: {
    Gradient,
  },
  props: {
    defaultDimensions: Boolean,
    gradientOnHover: Boolean,
    useGenericBackdropGradient: Boolean,
    error: Boolean,
    disabled: Boolean,
  },
  computed: {
    ...mapState([
      'isMobile',
    ]),
    frameStyle() {
      if (this.defaultDimensions) {
        return {
          width: '300px',
          height: '40px',
        }
      }
      return {}
    },
  },
}
