var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.circled ? _c('div', {
    staticClass: "circledLoadingSpinner"
  }, [_c('LoadingSpinner', {
    staticClass: "absolute center",
    attrs: {
      "type": _vm.type || 'greyscaleDark'
    }
  })], 1) : _c('div', {
    staticClass: "loadingSpinner",
    style: _vm.loadingSpinnerStyle
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 100 100",
      "preserveAspectRatio": "xMidYMid"
    }
  }, [_c('defs', [_c('mask', {
    attrs: {
      "id": "".concat(_vm.elementId, "_mask")
    }
  }, [_c('circle', {
    attrs: {
      "cx": "50",
      "cy": "50",
      "r": "40",
      "stroke": "#fff",
      "stroke-linecap": "round",
      "stroke-dasharray": "188.49555921538757 62.83185307179586",
      "stroke-width": "10"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#".concat(_vm.elementId, "_mask)")
    }
  }, [_c('foreignObject', {
    attrs: {
      "width": "100",
      "height": "100",
      "clip-path": "url(#clip)"
    }
  }, [_c('div', {
    style: {
      background: "conic-gradient(".concat(_vm.colors.lightest, ", ").concat(_vm.colors.darkest, " 80deg, ").concat(_vm.colors.lightest, ")")
    },
    attrs: {
      "id": "loadingSpinnerFill"
    }
  })])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }