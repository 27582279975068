var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-dimensions",
      rawName: "v-observe-dimensions",
      value: _vm.dimensions,
      expression: "dimensions"
    }],
    staticClass: "publicPageFrame",
    style: _vm.backgroundStyle
  }, [_c('div', {
    staticClass: "contentColumn",
    style: {
      width: "".concat(_vm.columnWidth, "px")
    }
  }, [_vm._t("default", null, null, {
    columnWidth: _vm.columnWidth
  }), _c('div', {
    staticClass: "verticalSpacer"
  }), _vm.showFooter ? _c('BrandFooter', {
    attrs: {
      "id": "brandFooter"
    }
  }) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }