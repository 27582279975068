var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8 5v14l11-7z",
      "fill": _vm.color || '#FFF'
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }