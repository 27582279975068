//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProfileLoadingScreen',
  props: {
    stepList: {
      type: Array,
      default() { return [] },
    },
    endFunction: Function,
  },
  data() {
    return {
      activeIndex: 0,
      timer: undefined,
    }
  },
  computed: {
    processedStepList() {
      const { stepList, activeIndex } = this
      return stepList
        .slice(activeIndex)
        .map((label, i) => ({
          label,
          opacity: Math.pow(i + 1, -1),
          scale: Math.pow(i + 1, -0.5),
          translateY: `${[...Array(i)].reduce((pr, _, j) => pr + Math.pow(j + 1, -0.5), 0) * 40}px`,
          labelTranslationDelay: `${0.3 * i}s`,
        }))
    },
  },
  methods: {
    stepCycle() {
      if (this.activeIndex === (this.stepList.length - 1)) {
        this.endFunction()
        clearInterval(this.timer)
        return
      }
      this.activeIndex = (this.activeIndex + 1) % Math.max(1, this.stepList.length)
    },
  },
  mounted() {
    this.timer = setInterval(this.stepCycle, 2000)
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer)
  },
}
