//
//
//
//
//
//
//

import RightArrow from '@/assets/svg/arrow/RightArrow.vue'
import TextInput from '@/components/universal/input/string/TextInput.vue'

export default {
  name: 'BrandCampaignUsernameInput',
  components: {
    RightArrow,
    TextInput,
  },
  props: {
    submitFunction: {
      type: Function,
      default: () => {},
    },
    error: String,
  },
  data() {
    return {
      usernameInputFocused: false,
      username: '',
    }
  },
}
