//
//
//
//
//
//
//
//
//
//
//

import InputFrame from '@/components/universal/input/InputFrame.vue'

export default {
  name: 'TextInput',
  components: {
    InputFrame,
  },
  props: {
    value: String,
    placeholder: String,
    inputType: { // 'text', 'email', 'number', 'date', 'search', 'url'
      type: String,
      default: 'text',
    },
    autocomplete: String,
    boldValue: Boolean,
    checkingValue: Boolean,
    error: Boolean,
    disabled: Boolean,
    obscureValues: Boolean,
    prefix: String,
    defaultDimensions: {
      type: Boolean,
      default: true,
    },
    maxlength: Number,
    multiline: Boolean,
    inputAttributes: { // for strings: { maxlength, autocapitalize }; for numbers: { min, max, step },
      type: Object,
      default: () => {},
    },
    useGenericBackdropGradient: Boolean,
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
}
