//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatNumber, numberMagnitudeString } from '@happstv/shared/util/utils'
import { YOUTUBE_NATIVE } from '@happstv/shared/util/multicastingConstants'

import { getPlatformIcon } from '@/util/connectedAccounts/externalPlatformUtils'
import { CUSTOM_CURRENCY_LIST } from '@/util/console/brandCampaignConsoleUtils'
import { getAccountListFromMediaKit, getDemographicsAccountListFromMediaKit } from '@/util/agent/agencyUtils'

import MediaKitAudienceDemographics from '@/components/agent/creator/mediaKit/MediaKitAudienceDemographics.vue'
import ReachLogo from '@/assets/svg/app/ReachLogo.vue'
import UserProfilePhoto from '@/components/user/UserProfilePhoto.vue'
import AgencyLogo from '@/components/agent/AgencyLogo.vue'

import MapPinIcon from '@/assets/svg/indicators/MapPinIcon.vue'
import OpenInNewWindowIcon from '@/assets/svg/admin/OpenInNewWindowIcon.vue'

export default {
  name: 'PublicMediaKit',
  components: {
    MediaKitAudienceDemographics,
    UserProfilePhoto,
    ReachLogo,
    AgencyLogo,
    MapPinIcon,
    OpenInNewWindowIcon,
  },
  props: {
    mediaKit: Object,
    hideHeaderAndFooter: Boolean,
  },
  data() {
    return {
      YOUTUBE_NATIVE,
      formatNumber,
      numberMagnitudeString,
      getPlatformIcon,
      ACCOUNT_METRICS_TO_DISPLAY: [
        {
          key: 'followerCount',
          label: 'Followers',
          get({ userResultStats, account }) {
            return userResultStats?.followerCount || account.followerCount
          },
        },
        {
          key: 'medianViewCount',
          label: 'Avg. Views',
          get({ userPostStats, account }) {
            return userPostStats?.medianViewCount || account.medianViewCount
          },
        },
      ],

      dimensions: { width: 0 },

      demographicsAccountIndex: 0,
    }
  },
  computed: {
    isMobile() { return this.dimensions.width < 800 }, // this ensures that if the media kit is loaded in a dialog, it always shows the mobile version
    agencyData() { return this.mediaKit?.agencyData || {} },
    creatorUserId() { return this.mediaKit?.creatorUserId },
    fullName() { return this.mediaKit?.fullName },
    location() { return this.mediaKit?.location },
    description() { return this.mediaKit?.description },
    tagList() { return this.mediaKit?.tags || [] },
    rateCardList() { return this.mediaKit?.rateCards || [] },
    demographicsByAccountUeid() { return this.mediaKit?.demographicsByAccountUeid || {} },
    manuallyAddedAccounts() { return this.mediaKit?.manuallyAddedAccounts },
    hiddenConnectedAccountIds() { return this.mediaKit?.hiddenConnectedAccountIds },
    fullAccountList() {
      const { mediaKit = {} } = this
      return getAccountListFromMediaKit(mediaKit)
    },
    demographicsAccountList() {
      return getDemographicsAccountListFromMediaKit(this.mediaKit || {})
    },
  },
  methods: {
    getCurrencyPrefix(rateCard = {}) {
      const { currency } = rateCard
      const selectedCurrency = CUSTOM_CURRENCY_LIST.find(({ key }) => key === currency)
      return selectedCurrency.prefix || '$'
    },
    hasMetrics(account) {
      const { ACCOUNT_METRICS_TO_DISPLAY } = this
      return ACCOUNT_METRICS_TO_DISPLAY
        .some(({ get }) => get(account) !== undefined)
    },
  },
}
