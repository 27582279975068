var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.linkEnabled ? _c('span', [_vm._t("default")], 2) : _vm.to ? _c('router-link', _vm._b({
    attrs: {
      "target": _vm.openExternal ? '_blank' : undefined
    }
  }, 'router-link', {
    to: _vm.to
  }, false), [_vm._t("default")], 2) : _c('a', {
    attrs: {
      "href": _vm.fallbackUrl,
      "target": _vm.openExternal ? '_blank' : undefined
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }