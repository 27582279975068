var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popupLayer"
  }, [_c('div', {
    staticClass: "popupCurtain fillParent",
    class: {
      blurBackground: _vm.blurBackground
    },
    style: {
      backgroundColor: _vm.curtainColor
    },
    attrs: {
      "role": "button",
      "aria-label": "Close Dialog"
    },
    on: {
      "mousedown": function mousedown($event) {
        $event.stopPropagation();
        _vm.closeable && _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "popupFrame absolute",
    class: _vm.frameClass,
    style: _vm.frameStyle
  }, [_c('div', {
    staticClass: "popupFrameInner",
    class: _vm.frameInnerClass
  }, [_c('transition', {
    attrs: {
      "appear": ""
    }
  }, [!_vm.resolvedComponent ? _c('LoadingSpinner', {
    staticClass: "circledLoadingSpinner absolute center",
    attrs: {
      "circled": ""
    }
  }) : _c(_vm.resolvedComponent, _vm._b({
    tag: "component",
    staticClass: "popupComponent",
    class: _vm.componentClass,
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, 'component', _vm.params, false))], 1)], 1), ['leftDrawer', 'rightDrawer'].includes(_vm.popupType) && _vm.closeable ? _c('PillButton', {
    attrs: {
      "large": "",
      "circular": "",
      "gradientType": "greyscaleMiddle",
      "id": "closeDrawerButton"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('CloseIconRounded')], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }