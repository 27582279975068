//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ReachLogo from '@/assets/svg/app/ReachLogo.vue'
import RightArrowGradient from '@/assets/svg/arrow/RightArrowGradient.vue'

export default {
  name: 'BrandFooter',
  components: {
    ReachLogo,
    RightArrowGradient,
  },
  methods: {
    launchReach() {
      this.$store.dispatch('analytics/LOG_EVENT', { name: 'reach_brand_footer_clicked' })
      setTimeout(function () { window.location = 'http://www.instagram.com/reach.me' }, 25)
      window.location = 'instagram://user?username=reach.me'
    },
  },
}
