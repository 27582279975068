var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tiktokUsername"
  }, [_c('TextInput', {
    attrs: {
      "prefix": _vm.username || _vm.usernameInputFocused ? '@' : '',
      "placeholder": _vm.username || _vm.usernameInputFocused ? 'username' : 'Enter your TikTok Username',
      "id": "tiktokUsernameInput",
      "error": _vm.error,
      "inputAttributes": {
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    },
    on: {
      "returnKey": function returnKey($event) {
        return _vm.submitFunction(_vm.username);
      }
    },
    nativeOn: {
      "focusin": function focusin($event) {
        _vm.usernameInputFocused = true;
      },
      "focusout": function focusout($event) {
        _vm.usernameInputFocused = false;
      }
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('PillButton', {
    attrs: {
      "circular": "",
      "disabled": !_vm.username
    },
    on: {
      "click": function click($event) {
        return _vm.submitFunction(_vm.username);
      }
    }
  }, [_c('RightArrow', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }