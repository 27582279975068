var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "menuFrame absolute",
    class: {
      bottom: _vm.bottom,
      left: _vm.left,
      show: _vm.show
    }
  }, [_c('transition', {
    attrs: {
      "appear": "",
      "duration": 300
    }
  }, [_vm.show ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function value() {
        return _vm.$emit('close');
      },
      expression: "() => $emit('close')"
    }],
    staticClass: "boxShadow",
    class: {
      centerHorizontal: _vm.center
    },
    style: {
      minWidth: _vm.minWidth,
      maxWidth: _vm.maxWidth
    },
    attrs: {
      "id": "menuContainer"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return function () {}($event);
      }
    }
  }, [_c('div', {
    style: {
      backgroundColor: _vm.backgroundColor
    },
    attrs: {
      "id": "menuTop"
    }
  }, [_vm.showTriangle ? _c('div', {
    staticClass: "centerHorizontal",
    style: {
      borderColor: _vm.backgroundColor
    },
    attrs: {
      "id": "menuTriangle"
    }
  }) : _vm._e()]), _c('div', {
    attrs: {
      "id": "menuContent"
    }
  }, [_c('div', {
    staticClass: "middleScrollbars",
    style: {
      backgroundColor: _vm.backgroundColor
    },
    attrs: {
      "id": "menuContentInner"
    }
  }, [_vm._t("default")], 2)])]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }