//
//
//
//
//
//
//
//
//
//
//
//

import Gradient from '@/components/universal/basic/Gradient.vue'
import CheckMarkIcon from '@/assets/svg/input/CheckMarkIcon.vue'

export default {
  name: 'PillButton',
  components: {
    Gradient,
    CheckMarkIcon,
  },
  props: {
    gradientType: {
      type: String,
      default: 'primary', // primary, secondary, greenAccent, redAccent, blueAccent, orangeAccent
    },
    gradientColorWithinBorder: String,
    small: Boolean,
    large: Boolean,
    extraLarge: Boolean,
    circular: Boolean,
    squared: Boolean,
    transparentOnDark: Boolean,
    translucentOnDark: Boolean,
    transparentOnLight: Boolean,
    translucentOnLight: Boolean,
    themeListItem: Boolean,
    error: Boolean,
    warning: Boolean,
    loading: Boolean,
    disabled: Boolean,
    hideGradient: Boolean,
    copyStringOnClick: String,
    mergeLeft: Boolean,
    mergeRight: Boolean,
  },
  data() {
    return {
      copied: false,
    }
  },
  computed: {
    pillButtonClass() {
      const {
        disabled,
        small,
        large,
        extraLarge,
        circular,
        squared,
        gradientType,
        hideGradient,
        transparentOnDark,
        translucentOnDark,
        transparentOnLight,
        translucentOnLight,
        mergeLeft,
        mergeRight,
        themeListItem,
        error,
        warning,
      } = this

      const onDark = transparentOnDark || translucentOnDark
      const onLight = transparentOnLight || translucentOnLight

      return {
        disabled,
        small,
        large,
        extraLarge,
        circular,
        squared,
        hideGradient,
        isGreyscale: (gradientType || '').startsWith('greyscale') || onDark || onLight,
        onDark,
        onLight,
        translucentOnDark,
        translucentOnLight,
        mergeLeft,
        mergeRight,
        themeListItem,
        error,
        warning,
      }
    },
    showGradient() {
      return !this.transparentOnDark
        && !this.translucentOnDark
        && !this.transparentOnLight
        && !this.translucentOnLight
        && !this.hideGradient
        && !this.themeListItem
    },
    fontColor() {
      if (this.themeListItem) {
        if (this.error) return '#FF1272'
        if (this.warning) return '#FF6A14'
        return '#FFF'
      }
      if (this.transparentOnDark) return '#FFF'
      if (this.translucentOnDark) return '#FFF'
      if (this.transparentOnLight) return '#000'
      if (this.translucentOnLight) return '#000'

      switch (this.gradientType) {
        case 'yellowAccent':
        case 'blueAccent':
        case 'greyscaleLight':
        case 'greyscaleLighter':
        case 'greyscaleLightest':
          return '#000'
        default:
          return '#FFF'
      }
    },
  },
  methods: {
    click() {
      this.$emit('click')

      const { copyStringOnClick } = this
      if (copyStringOnClick && !this.copied) {
        navigator.clipboard.writeText(copyStringOnClick)
        this.copied = true

        setTimeout(() => {
          this.copied = false
        }, 3000)
      }
    },
  },
}
