/* eslint-disable no-console,class-methods-use-this */

/**
 * A simple and lightweight logging class.
 *
 * To enable this, open up the console in the browser and run:
 *
 *     localStorage.loggingEnabled = true
 *
 * This is compatible with pino's functions.
 */
class Logger {
  loggingEnabled() {
    return (localStorage.getItem('loggingEnabled') === 'true')
  }

  debug(...args) {
    if (this.loggingEnabled()) console.debug(...args)
  }

  info(...args) {
    if (this.loggingEnabled()) {
      if ((args[0] || {}).firestore) {
        const fs = args[0].firestore
        console.info(`${fs.operation} ${fs.path} ${fs.duration}ms`)
      } else {
        console.info(...args)
      }
    }
  }

  warn(...args) {
    if (this.loggingEnabled()) console.warn(...args)
  }

  error(...args) {
    console.error(...args)
  }

  fatal(...args) {
    console.error(...args)
  }

  trace(...args) {
    console.trace(...args)
  }
}

const logger = new Logger()

export default logger
