var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "appBadges"
  }, [_c('a', {
    attrs: {
      "href": _vm.appStoreDynamicLink
    },
    on: {
      "click": function click($event) {
        return _vm.LOG_EVENT({
          name: 'app_store_button_clicked'
        });
      }
    }
  }, [_c('AppleBadge')], 1), _c('a', {
    attrs: {
      "href": _vm.googlePlayDynamicLink
    },
    on: {
      "click": function click($event) {
        return _vm.LOG_EVENT({
          name: 'google_play_button_clicked'
        });
      }
    }
  }, [_c('GoogleBadge')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }