var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "viewBox": "0 0 133 22",
      "fill": _vm.useGradient ? 'url(#ReachLogoGradient)' : _vm.color,
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M25.368 20.636H17.948L14.112 14.224H6.384V20.636H0V0.644H17.024C19.7867 0.644 21.784 1.18533 23.016 2.268C24.248 3.35067 24.864 5.00267 24.864 7.224C24.864 8.92267 24.4907 10.2107 23.744 11.088C22.9973 11.9467 21.9333 12.544 20.552 12.88V13.104L25.368 20.636ZM15.484 9.688C16.3053 9.688 16.8933 9.52 17.248 9.184C17.6213 8.848 17.808 8.33467 17.808 7.644C17.808 6.95333 17.6213 6.44933 17.248 6.132C16.8933 5.796 16.3053 5.628 15.484 5.628H6.384V9.688H15.484Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M33.9192 8.288H48.5352V12.516H33.9192V16.044H49.4592V20.636H27.5352V0.644H49.1512V5.208H33.9192V8.288Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M70.6213 17.472H59.2812L57.7692 20.636H50.7132L60.8213 0.644H69.1933L79.2173 20.636H72.1612L70.6213 17.472ZM68.4092 12.852L65.0212 5.908H64.8532L61.5213 12.852H68.4092Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M97.8622 8.456C97.7875 7.896 97.5355 7.35467 97.1062 6.832C96.6768 6.30933 96.0422 5.88 95.2022 5.544C94.3809 5.18933 93.3822 5.012 92.2062 5.012C90.0409 5.012 88.3982 5.49733 87.2782 6.468C86.1769 7.43867 85.6262 8.82933 85.6262 10.64C85.6262 12.4507 86.1769 13.8413 87.2782 14.812C88.3982 15.7827 90.0409 16.268 92.2062 16.268C93.9795 16.268 95.3329 15.904 96.2662 15.176C97.1995 14.448 97.7222 13.524 97.8342 12.404H105.534C105.46 15.2973 104.284 17.5 102.006 19.012C99.7475 20.524 96.4902 21.28 92.2342 21.28C87.8475 21.28 84.4782 20.4027 82.1262 18.648C79.7929 16.8747 78.6262 14.2053 78.6262 10.64C78.6262 7.07467 79.7929 4.41467 82.1262 2.66C84.4782 0.886667 87.8475 0 92.2342 0C96.4529 0 99.6915 0.737333 101.95 2.212C104.209 3.668 105.385 5.74933 105.478 8.456H97.8622Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M132.84 0.644V20.636H126.456V12.908H114.556V20.636H108.172V0.644H114.556V7.98H126.456V0.644H132.84Z"
    }
  }), _vm.useGradient ? [_c('linearGradient', {
    attrs: {
      "id": "ReachLogoGradient",
      "gradientUnits": "userSpaceOnUse",
      "gradientTransform": "rotate(45)"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": _vm.firstColor
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": _vm.secondColor
    }
  })], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }