var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "clickable",
    on: {
      "click": function click($event) {
        return _vm.launchReach();
      }
    }
  }, [_c('div', {
    staticClass: "linkContainer"
  }, [_c('ReachLogo', {
    staticClass: "logo",
    attrs: {
      "useGradient": ""
    }
  }), _c('RightArrowGradient')], 1), _c('div', {
    staticClass: "textContainer"
  }, [_vm._v(" An exclusive network for musicians to reach and grow audience. ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }