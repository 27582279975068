//
//
//
//
//
//
//
//
//
//
//

import LoadingSpinner from '@/components/universal/basic/LoadingSpinner.vue'

import PersonIcon from '@/assets/svg/user/PersonIcon.vue'

export default {
  name: 'UserProfilePhoto',
  components: {
    LoadingSpinner,
    PersonIcon,
  },
  props: {
    user: Object,
    backgroundColor: {
      type: String,
      default: '#494B58',
    },
    forceMediumQuality: Boolean,
    displayAsSquare: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fontSizePx: 0,
      photoLoading: true,
    }
  },
  computed: {
    photoUrl() {
      if (!this.user) return undefined
      if (this.forceMediumQuality) return this.user.profileMediumPhotoUrl || this.user.profilePhotoUrl || this.user.profileThumbUrl
      return this.user.profileThumbUrl || this.user.profileMediumPhotoUrl
    },
    photoPath() {
      if (!this.user) return undefined
      return this.user.profilePhotoPath
    },
    transformations() {
      if (this.forceMediumQuality) return [{width:256,height:256}]
      return [{width:64,height:64}]
    },
    userNamesArray() {
      if (this.user) {
        if (this.user.fullName) return this.user.fullName.split(' ')
        // relevant for teams
        if (this.user.name) return this.user.name.split(' ')
      }
      return ['•••']
    },
    borderRadius() {
      if (this.displayAsSquare) return '5px'
      return '50%'
    },
  },
  mounted() {
    if (this.user && !this.photoUrl) this.fontSizePx = this.$refs.photoFrame.offsetHeight * 0.6
  },
}
