var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(_vm._s(_vm.string)), _vm.showFullDateContext ? _c('InlineContext', {
    staticClass: "absolute centerHorizontal",
    attrs: {
      "id": "fullDateContext",
      "delay": "0.5s",
      "maxWidth": "150px"
    }
  }, [_vm._v(_vm._s(_vm.fullDateContext))]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }