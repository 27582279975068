//
//
//
//
//
//
//

export default {
  name: 'PersonIcon',
  props: {
    color: String,
  },
}
