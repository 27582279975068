//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MaybeRouterLink',
  props: {
    linkEnabled: Boolean,
    to: Object,
    fallbackUrl: String,
    openExternal: Boolean,
  },
}
