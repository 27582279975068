var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.externalPlatformsInfo ? _c('LoadingSpinner', {
    attrs: {
      "defaultDimensions": false,
      "type": _vm.loadingSpinnerType
    }
  }) : _vm.circled ? _c('div', {
    staticClass: "circledPlatformIcon",
    style: {
      background: _vm.circleColor
    }
  }, [_c('PlatformIcon', _vm._b({
    staticClass: "absolute center"
  }, 'PlatformIcon', {
    platformId: _vm.platformId
  }, false))], 1) : _vm.template ? _c({
    template: _vm.template
  }, {
    tag: "component"
  }) : _vm.platformId === 'reach' ? _c('ReachIcon', {
    attrs: {
      "useGradient": _vm.usePlatformColor,
      "color": _vm.color
    }
  }) : _c('GenericSocialIcon', {
    staticClass: "genericPlatformIcon",
    attrs: {
      "color": _vm.color
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }