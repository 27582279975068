var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Gradient', {
    staticClass: "switchInput clickable",
    class: {
      on: _vm.value,
      disabled: _vm.disabled
    },
    attrs: {
      "type": _vm.value ? 'primary' : 'greyscaleMiddle'
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.$emit('input', !_vm.value);
      }
    }
  }, [_c('div', {
    attrs: {
      "id": "switchCircle"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }