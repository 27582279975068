//
//
//
//
//
//
//
//
//
//

import { numberMagnitudeString } from '@happstv/shared/util/utils'
import AppBadges from '@/components/app/AppBadges.vue'

export default {
  name: 'BrandCampaignPotentialResult',
  components: {
    AppBadges,
  },
  props: {
    username: String,
    maxPossibleEarnings: Number,
    brandCampaignId: String,
  },
  data() {
    return {
      numberMagnitudeString,
    }
  },
}
