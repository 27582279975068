var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "activeCampaignSection"
  }, [_c('p', [_vm._v(_vm._s(_vm.titleText))]), _c('div', {
    staticClass: "activeCampaignFlex"
  }, [_c('div', {
    staticClass: "horizontalCampaignList browserScrollbars"
  }, _vm._l(_vm.processedCampaignList, function (campaign) {
    return _c('BrandCampaignVideoPreview', {
      key: campaign.id,
      staticClass: "campaignPreviewCard",
      attrs: {
        "type": "video/mp4",
        "src": ((campaign.profileVideoList || [])[0] || {}).url
      }
    });
  }), 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }