//
//
//
//
//
//
//
//
//

import { inString } from '@happstv/shared/util/relativeTimeString'
import { date } from '@happstv/shared/util/utils'
import PlayIcon from '@/assets/svg/av/output/PlayIcon.vue'

export default {
  name: 'BrandCampaignVideoPreview',
  components: {
    PlayIcon,
  },
  props: {
    src: String,
    type: {
      type: String,
      default: 'video/mp4',
    },
    playable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      videoPlaying: false,
      inString,
      date,
    }
  },
  methods: {
    toggleStatus() {
      if(this.$refs.previewVideo.paused && this.playable) {
        this.$refs.previewVideo.play()
        this.videoPlaying = true
      } else {
        this.$refs.previewVideo.pause()
        this.videoPlaying = false
      }
    },
  },
}
