var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition-group', {
    staticClass: "profileLoadingScreen",
    attrs: {
      "appear": "",
      "tag": "div",
      "duration": {
        leave: 200
      }
    }
  }, _vm._l(_vm.processedStepList, function (_ref) {
    var label = _ref.label,
        opacity = _ref.opacity,
        translateY = _ref.translateY,
        scale = _ref.scale,
        labelTranslationDelay = _ref.labelTranslationDelay;
    return _c('div', {
      key: label,
      staticClass: "stepBox absolute center"
    }, [_c('div', {
      staticClass: "stepBoxInner",
      style: {
        opacity: opacity,
        transform: "translateY(".concat(translateY, ") scale(").concat(scale, ")")
      }
    }, [_c('h3', {
      style: {
        transitionDelay: labelTranslationDelay
      }
    }, [_vm._v(_vm._s(label))])])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }