var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.properties.path ? _c('ik-image', {
    staticClass: "agencyLogo",
    class: {
      invert: _vm.properties.invert
    },
    style: {
      height: "".concat(_vm.height, "px")
    },
    attrs: {
      "path": _vm.properties.path,
      "transformation": [{
        height: _vm.height * 3
      }]
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }