var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, {
    tag: "component"
  }, [_vm._l(_vm.lineList, function (line, i) {
    return [i > 0 ? _c('br', {
      key: i
    }) : _vm._e(), _vm._v(_vm._s(line) + " ")];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }