var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "photoFrame",
    staticClass: "userProfilePhoto inlineBlock",
    style: {
      borderRadius: _vm.borderRadius,
      backgroundColor: !_vm.photoUrl && !_vm.photoPath || _vm.photoLoading ? _vm.backgroundColor : undefined
    }
  }, [!_vm.user || (_vm.photoUrl || _vm.photoPath) && _vm.photoLoading ? _c('LoadingSpinner', {
    staticClass: "absolute center",
    attrs: {
      "defaultDimensions": false,
      "id": "loading"
    }
  }) : _vm._e(), _vm.photoPath ? _c('ik-image', {
    staticClass: "fillParent",
    style: {
      borderRadius: _vm.borderRadius
    },
    attrs: {
      "id": "photo",
      "path": _vm.photoPath,
      "transformation": _vm.transformations,
      "alt": "Profile photo"
    },
    nativeOn: {
      "load": function load($event) {
        _vm.photoLoading = false;
      }
    }
  }) : _vm.photoUrl ? _c('img', {
    staticClass: "fillParent",
    style: {
      borderRadius: _vm.borderRadius
    },
    attrs: {
      "id": "photo",
      "src": _vm.photoUrl
    },
    on: {
      "load": function load($event) {
        _vm.photoLoading = false;
      }
    }
  }) : _vm.user ? _c('div', {
    staticClass: "fillParent",
    attrs: {
      "id": "fallback"
    }
  }, [_c('PersonIcon', {
    staticClass: "centerHorizontal"
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }