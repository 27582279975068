//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { makeReachDynamicLink } from '@happstv/shared/util/makeReachDynamicLink'
import { makeReachUrl } from '@happstv/shared/util/makeReachUrl'
import GoogleBadge from '@/assets/svg/app/GoogleBadge.vue'
import AppleBadge from '@/assets/svg/app/AppleBadge.vue'

export default {
  name: 'AppBadges',
  props: {
    brandCampaignId: String,
  },
  computed: {
    universalLink() {
      const { brandCampaignId } = this
      if (brandCampaignId) return makeReachUrl(this.brandCampaignId, { isCampaign: true, avoidLocalhost: true })
      return makeReachUrl('', { avoidLocalhost: true })
    },
    googlePlayDynamicLink() { return makeReachDynamicLink(this.universalLink, { fallbackToGooglePlay: true }) },
    appStoreDynamicLink() { return makeReachDynamicLink(this.universalLink, { fallbackToAppStore: true }) },
  },
  components: {
    GoogleBadge,
    AppleBadge,
  },
  methods: {
    ...mapActions('analytics', [
      'LOG_EVENT',
    ]),
  },
}
