var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: {
      disabled: _vm.disabled
    },
    attrs: {
      "viewBox": "-4 -4 20 28",
      "fill": _vm.useGradient ? 'url(#ReachLogoGradient)' : _vm.color,
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.05927 0.363353L0.36323 5.06049C-0.121077 5.54491 -0.121077 6.34535 0.36323 6.82977C0.847538 7.31419 1.6478 7.31419 2.1321 6.82977L5.92263 3.03836L9.71315 6.82977C10.1975 7.31419 10.9977 7.31419 11.482 6.82977C11.7347 6.57706 11.84 6.26102 11.84 5.94511C11.84 5.62922 11.7137 5.29218 11.482 5.06045L6.8281 0.363315C6.3438 -0.121105 5.54354 -0.121105 5.05923 0.363315L5.05927 0.363353Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M5.05927 6.76679L0.36323 11.4639C-0.121077 11.9483 -0.121077 12.7488 0.36323 13.2332C0.847538 13.7176 1.6478 13.7176 2.1321 13.2332L5.92263 9.4418L9.71315 13.2332C10.1975 13.7176 10.9977 13.7176 11.482 13.2332C11.7347 12.9805 11.84 12.6645 11.84 12.3485C11.84 12.0327 11.7137 11.6956 11.482 11.4639L6.8281 6.76675C6.3438 6.28218 5.54354 6.28218 5.05923 6.76675L5.05927 6.76679Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M5.94372 12.8125C5.60675 12.8125 5.29095 12.9389 5.05927 13.1706L0.36323 17.8677C-0.121077 18.3521 -0.121077 19.1526 0.36323 19.637C0.847538 20.1214 1.6478 20.1214 2.1321 19.637L5.92263 15.8456L9.71315 19.637C10.1975 20.1214 10.9977 20.1214 11.482 19.637C11.7347 19.3843 11.84 19.0683 11.84 18.7524C11.84 18.4365 11.7137 18.0994 11.482 17.8677L6.8281 13.1706C6.59645 12.9389 6.28062 12.8125 5.94365 12.8125L5.94372 12.8125Z"
    }
  }), _vm.useGradient ? [_c('linearGradient', {
    attrs: {
      "id": "ReachLogoGradient",
      "gradientUnits": "userSpaceOnUse",
      "x1": "0",
      "y1": "20",
      "x2": "12",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": _vm.disabled ? '#B1B2B7' : _vm.firstColor
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": _vm.disabled ? '#B1B2B7' : _vm.secondColor
    }
  })], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }