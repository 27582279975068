var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Gradient', {
    staticClass: "inputFrame",
    class: {
      disabled: _vm.disabled,
      error: _vm.error,
      gradientOnHover: _vm.gradientOnHover,
      genericBackdropGradient: _vm.useGenericBackdropGradient
    },
    style: _vm.frameStyle,
    attrs: {
      "type": _vm.error ? 'error' : 'primary',
      "colorWithinBorder": "#000"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }