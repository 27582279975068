//
//
//
//
//
//
//
//

export default {
  name: 'InlineContext',
  props: {
    placement: {
      type: String,
      default: 'top',
    },
    maxWidth: String,
    placementOffset: String,
    delay: String,
    backgroundColor: {
      type: String,
      default: '#FFF',
    },
    textColor: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    containerClass() {
      const { placement } = this

      const result = {
        [placement]: true,
      }

      if (['top', 'bottom'].includes(placement)) result.centerHorizontal = true
      else result.centerVertical = true

      return result
    },
    containerStyle() {
      const result = {
        width: this.maxWidth || '100px',
      }

      const { placement, placementOffset } = this

      switch (placement) {
        case 'top':
          result.bottom = `calc(100% + ${placementOffset || '0px'})`
          break
        case 'bottom':
          result.top = `calc(100% + ${placementOffset || '0px'})`
          break
        case 'left':
          result.right = `calc(100% + ${placementOffset || '0px'})`
          result.textAlign = 'right'
          break
        case 'right':
          result.left = `calc(100% + ${placementOffset || '0px'})`
          result.textAlign = 'left'
          break
        default:
          break
      }

      return result
    },
  },
}
