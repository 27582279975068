//
//
//
//
//
//

export default {
  name: 'SnapchatIcon',
  props: {
    color: {
      type: String,
      default: '#FFF',
    },
  },
}
