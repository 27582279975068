var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "10",
      "height": "9",
      "viewBox": "0 0 10 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.81641 4.4043C9.81641 4.22021 9.73389 4.03613 9.60059 3.90283L6.36328 0.671875C6.21094 0.525879 6.0459 0.456055 5.88086 0.456055C5.4873 0.456055 5.20801 0.735352 5.20801 1.10352C5.20801 1.30029 5.29053 1.46533 5.41748 1.58594L6.38232 2.56348L7.73438 3.79492L6.60449 3.7251H0.967773C0.548828 3.7251 0.269531 4.00439 0.269531 4.4043C0.269531 4.81055 0.548828 5.08984 0.967773 5.08984H6.60449L7.74072 5.01367L6.38232 6.25146L5.41748 7.22266C5.29053 7.34961 5.20801 7.5083 5.20801 7.70508C5.20801 8.07959 5.4873 8.35254 5.88086 8.35254C6.0459 8.35254 6.21094 8.28271 6.35693 8.14307L9.60059 4.91211C9.73389 4.77881 9.81641 4.58838 9.81641 4.4043Z",
      "fill": "url(#RightArrowGradient)"
    }
  }), _c('linearGradient', {
    attrs: {
      "id": "RightArrowGradient",
      "x1": "-1",
      "y1": "13",
      "x2": "15.4061",
      "y2": "0.454148",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": _vm.firstColor
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": _vm.secondColor
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }