var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contextContainer",
    class: _vm.containerClass,
    style: _vm.containerStyle,
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return function () {}($event);
      }
    }
  }, [_c('div', {
    staticClass: "condensed boxShadow",
    style: {
      animationDelay: _vm.delay || '0.15s',
      backgroundColor: _vm.backgroundColor,
      color: _vm.textColor
    }
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }