//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  DRAWER_WIDTH,
} from '@/constants/app/appConstants'

import PillButton from '@/components/universal/basic/PillButton.vue'
import CloseIconRounded from '@/assets/svg/navigation/CloseIconRounded.vue'

export default {
  name: 'PopupLayer',
  components: {
    PillButton,
    CloseIconRounded,
  },
  props: {
    component: [Object, Function],
    params: Object,
    popupType: {
      type: String,
      default: 'dialog', // dialog, leftDrawer, rightDrawer
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    curtainColor: {
      type: String,
      default: '#0000009F',
    },
    blurBackground: {
      type: Boolean,
      default: false,
    },
    center: { // only relevant to dialogs
      type: Object,
      default: undefined,
    },
    drawerWidth: { // only relevant to drawers
      type: String,
      default: `${DRAWER_WIDTH}px`,
    },
  },
  data() {
    return {
      resolvedComponent: undefined,
    }
  },
  computed: {
    frameClass() {
      const { popupType } = this

      const result = { [popupType]: true }

      switch (popupType) {
        case 'leftDrawer':
        case 'rightDrawer':
          result.boxShadow = true
          break
        default: // dialog
          result.center = true
          break
      }

      return result
    },
    frameStyle() {
      const { popupType, center, drawerWidth } = this

      switch (popupType) {
        case 'leftDrawer':
        case 'rightDrawer':
          return {
            [popupType === 'leftDrawer' ? 'left' : 'right']: '0',
            top: '0',
            bottom: '0',
            width: drawerWidth,
            backgroundColor: '#1B1C21',
          }
        default: { // dialog
          return {
            left: (center || {}).x,
            top: (center || {}).y,
          }
        }
      }
    },
    frameInnerClass() {
      const { popupType } = this

      switch (popupType) {
        case 'leftDrawer':
        case 'rightDrawer':
          return { fillParent: true }
        default:
          return {}
      }
    },
    componentClass() {
      const { popupType } = this

      switch (popupType) {
        case 'leftDrawer':
        case 'rightDrawer':
          return { fillParent: true }
        default:
          return { boxShadow: true }
      }
    },
  },
  watch: {
    component: {
      immediate: true,
      async handler(newValue) {
        if (!newValue) return

        switch (typeof newValue) {
          case 'object':
            this.resolvedComponent = newValue
            break
          case 'function':
            this.resolvedComponent = (await newValue()).default
            break
        }
      },
    },
  },
}
