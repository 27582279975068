/* eslint-disable no-param-reassign */

const eventIsOutsideEl = (event, el) => !(el === event.target || el.contains(event.target))

export default {
  inserted(el, binding) {
    el.handlePointerDown = (event) => {
      el.clicking = eventIsOutsideEl(event, el)
    }
    el.handlePointerLeave = () => {
      el.clicking = false
    }
    el.handleClick = (event) => {
      if (el.clicking && eventIsOutsideEl(event, el)) binding.value(event)
      el.clicking = false
    }

    el.clicking = false
    document.body.addEventListener('pointerdown', el.handlePointerDown)
    document.body.addEventListener('pointerleave', el.handlePointerLeave)
    document.body.addEventListener('click', el.handleClick)
  },
  unbind(el) {
    document.body.removeEventListener('pointerdown', el.handlePointerDown)
    document.body.removeEventListener('pointerleave', el.handlePointerLeave)
    document.body.removeEventListener('click', el.handleClick)
  },
}
