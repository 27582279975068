//
//
//
//
//

import { date, filterObjectKeys } from '@happstv/shared/util/utils'
import { relativeTimeString, agoString, fromNowString } from '@happstv/shared/util/relativeTimeString'

export default {
  name: 'RelativeTimeString',
  props: {
    date: [Object, Date, Number], // can be date, timestamp, or millis
    // one of the two following params may be passed, or neither, but not both
    disableFromNow: Boolean,
    disableAgo: Boolean,
    showAsTimer: Boolean,
    includeNumericTime: Boolean,
    preferShort: Boolean,
    singleLetters: Boolean,
    relativeThreshold: Number,
    hoursThreshold: Number,
    daysThreshold: Number,
    weeksThreshold: Number,
    disableFullDateContext: Boolean,
    stringAlterationFunction: Function,
  },
  data() {
    return {
      timer: undefined,
      tornDown: false,
      string: '',
    }
  },
  computed: {
    options() {
      return filterObjectKeys(this, [
        'showAsTimer',
        'includeNumericTime',
        'preferShort',
        'singleLetters',
        'relativeThreshold',
        'hoursThreshold',
        'daysThreshold',
        'weeksThreshold',
      ])
    },
    realDate() {
      return date(this.date)
    },
    showFullDateContext() {
      const {
        realDate,
        disableFullDateContext,
        includeNumericTime,
        relativeThreshold,
      } = this
      if (disableFullDateContext) return false
      if (includeNumericTime) return false
      if (relativeThreshold >= 0 && Math.abs(realDate.getTime() - Date.now()) > relativeThreshold) return false
      return true
    },
    fullDateContext() {
      const { realDate } = this
      const fullDateOptions = { relativeThreshold: 0 }
      if (this.disableFromNow) return agoString(realDate, fullDateOptions)
      if (this.disableAgo) return fromNowString(realDate, fullDateOptions)
      return relativeTimeString(realDate, fullDateOptions)
    },
  },
  methods: {
    updateString() {
      if (this.timer) clearTimeout(this.timer)
      if (this.tornDown) return

      const { realDate } = this
      let string
      if (this.disableFromNow) string = agoString(realDate, this.options)
      else if (this.disableAgo) string = fromNowString(realDate, this.options)
      else string = relativeTimeString(realDate, this.options)

      const { stringAlterationFunction } = this
      if (stringAlterationFunction) this.string = stringAlterationFunction(string)
      else this.string = string

      let delay = 1000 * 60 * 60
      if (this.showAsTimer) {
        delay = 1000
      } else {
        const relativeMillis = Math.abs(new Date().getTime() - realDate.getTime())
        if (relativeMillis < 1000 * 60 * 60) delay = 1000 * 5
        else if (relativeMillis < 1000 * 60 * 60 * 12) delay = 1000 * 60
      }

      this.timer = setTimeout(this.updateString, delay)
    },
    visibilityChanged() {
      if (document.visibilityState === 'visible') this.updateString()
    },
  },
  watch: {
    date() {
      this.updateString()
    },
  },
  created() {
    this.updateString()
    document.addEventListener('visibilitychange', this.visibilityChanged)
  },
  destroyed() {
    this.tornDown = true
    document.removeEventListener('visibilitychange', this.visibilityChanged)
  },
}
