//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Tooltip',
  props: {
    show: Boolean,
    showOnHover: Boolean,
    direction: { // 'up' or 'down'
      type: String,
      default: 'up',
    },
    verticalOffset: {
      type: String,
      default: '3px',
    },
    horizontalOffset: {
      type: String,
      default: '0px',
    },
    triangleHorizontalOffset: {
      type: String,
      default: '0px',
    },
    backgroundColor: {
      type: String,
      default: '#494B58',
    },
    borderColor: String,
    textColor: {
      type: String,
      default: '#FFF',
    },
    minWidth: String,
    maxWidth: {
      type: String,
      default: '250px',
    },
  },
  data() {
    return {
      isHovered: false,

      showProcessedDelayed: false,
    }
  },
  computed: {
    showProcessed() {
      return this.show || (this.showOnHover && this.isHovered)
    },

    tooltipFrameStyle() {
      const { maxWidth, direction, verticalOffset, horizontalOffset } = this

      const result = {
        left: '50%',
        transform: `translateX(calc(-50% + ${horizontalOffset}))`,
        width: maxWidth,
      }

      switch (direction) {
        case 'up':
          result.bottom = '100%'
          if (verticalOffset) result.transform += ` translateY(calc(0px - (${verticalOffset})))`
          // result.paddingBottom = verticalOffset
          break
        case 'down':
          result.top = '100%'
          if (verticalOffset) result.transform += ` translateY(${verticalOffset})`
          // result.paddingTop = verticalOffset
          break
        default: break
      }

      return result
    },
    tooltipBubbleStyle() {
      const { backgroundColor, textColor, borderColor, minWidth } = this

      const result = {
        backgroundColor,
        color: textColor,
      }

      if (borderColor) {
        result.border = `1px solid ${borderColor}`
      }

      if (minWidth) {
        Object.assign(result, { minWidth })
      }

      return result
    },
  },
  watch: {
    showProcessed: {
      immediate: true,
      handler(newValue) {
        setTimeout(() => {
          this.showProcessedDelayed = newValue
        }, 100)
      },
    },
  },
  methods: {
    clickOutside() {
      if (this.showProcessedDelayed) {
        this.$emit('close')
      }
    },
  },
}
