//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ReachIcon',
  props: {
    useGradient: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#FFF',
    },
    firstColor: {
      type: String,
      default: '#8F00FF',
    },
    secondColor: {
      type: String,
      default: '#0085FF',
    },
    disabled: Boolean,
  },
}
