var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "pillButton clickable",
    class: _vm.pillButtonClass,
    attrs: {
      "disabled": _vm.disabled || _vm.loading || _vm.copied
    },
    on: {
      "click": _vm.click
    }
  }, [_vm.showGradient || _vm.copied ? _c('Gradient', {
    attrs: {
      "id": "gradient",
      "type": _vm.copied ? 'greyscaleLightest' : _vm.gradientType,
      "colorWithinBorder": _vm.gradientColorWithinBorder
    }
  }) : _vm._e(), _vm.loading ? _c('LoadingSpinner', {
    staticClass: "absolute center",
    attrs: {
      "id": "loading",
      "defaultDimensions": false,
      "type": _vm.fontColor === '#000' ? 'greyscaleDark' : undefined
    }
  }) : _vm.copied ? _c('CheckMarkIcon', {
    staticClass: "absolute center",
    attrs: {
      "color": "#000",
      "id": "copied"
    }
  }) : _vm._e(), _c('div', {
    style: {
      color: _vm.fontColor
    },
    attrs: {
      "id": "buttonContent"
    }
  }, [_vm._t("default")], 2), !_vm.themeListItem ? _c('div', {
    attrs: {
      "id": "buttonCover"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }