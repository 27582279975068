var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.33203 18.125C8.43788 18.4856 8.65767 18.8022 8.95849 19.0274C9.25932 19.2526 9.62499 19.3743 10.0008 19.3743C10.3766 19.3743 10.7422 19.2526 11.0431 19.0274C11.3439 18.8022 11.5637 18.4856 11.6695 18.125",
      "stroke": _vm.color,
      "stroke-width": "1.25",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M10 2.5V0.625",
      "stroke": _vm.color,
      "stroke-width": "1.25",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M10 2.5C11.6576 2.5 13.2473 3.15848 14.4194 4.33058C15.5915 5.50268 16.25 7.0924 16.25 8.75C16.25 14.6217 17.5 15.625 17.5 15.625H2.5C2.5 15.625 3.75 14.0283 3.75 8.75C3.75 7.0924 4.40848 5.50268 5.58058 4.33058C6.75268 3.15848 8.3424 2.5 10 2.5V2.5Z",
      "stroke": _vm.color,
      "stroke-width": "1.25",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }