//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex'

import {
  TOP_NAV_HEIGHT,
} from '@/constants/app/appConstants'

import PopupLayer from '@/components/universal/overlapping/PopupLayer.vue'

export default {
  name: 'OverlayManager',
  components: {
    PopupLayer,
  },
  data() {
    return {
      TOP_NAV_HEIGHT,
    }
  },
  computed: {
    ...mapState([
      'isBeta',
      'headerTranslation',
    ]),
    ...mapState('overlays', [
      'fullscreenPopups',
    ]),
    ...mapGetters('overlays', [
      'currentToast',
    ]),
  },
  methods: {
    ...mapActions('overlays', [
      'REMOVE_FULLSCREEN_POPUP_AT_INDEX',
    ]),

    toastMouseEnter() {
      this.$store.dispatch('overlays/SET_CURRENT_TOAST_HOVER', true)
    },
    toastMouseLeave() {
      this.$store.dispatch('overlays/SET_CURRENT_TOAST_HOVER', false)
    },
  },
}
