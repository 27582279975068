//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import colorizeIconTemplate from '@happstv/shared/util/connectedAccounts/colorizeIconTemplate'

import ReachIcon from '@/assets/svg/app/ReachIcon.vue'
import GenericSocialIcon from '@/assets/svg/connectedAccounts/GenericSocialIcon.vue'

export default {
  name: 'PlatformIcon',
  components: {
    ReachIcon,
    GenericSocialIcon,
  },
  props: {
    platformId: String,
    usePlatformColor: Boolean,
    color: {
      type: String,
      default: '#FFF',
    },
    loadingSpinnerType: {
      type: String,
      default: undefined,
    },
    circled: Boolean,
  },
  computed: {
    ...mapState('connectedAccounts', [
      'externalPlatformsInfo',
    ]),

    platformInfo() {
      const { externalPlatformsInfo = {}, platformId } = this
      return externalPlatformsInfo[platformId] || {}
    },
    circleColor() {
      const { usePlatformColor, platformId, platformInfo } = this
      if (usePlatformColor) {
        if (platformId === 'reach') return 'linear-gradient(45deg, #8F00FF, #0085FF)'
        if (platformInfo.platformPrimaryColor) return platformInfo.platformPrimaryColor
      }
      return '#494B58'
    },
    template() {
      const { platformInfo, usePlatformColor, color } = this
      if (usePlatformColor) {
        const { platformColorIconSvg } = platformInfo
        if (platformColorIconSvg) {
          return platformColorIconSvg
        }
      } else {
        const { platformIconSvgTemplate } = platformInfo
        if (platformIconSvgTemplate) {
          return colorizeIconTemplate(platformIconSvgTemplate, { color, foregroundColor: 'transparent' })
        }
      }
      return undefined
    },
  },
}
