var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "transform": "translate(12, 12) scale(0.9) translate(-12, -13)",
      "d": "M12,12c2.2091675,0,4-1.7908936,4-4c0-2.2091675-1.7908325-4-4-4S8,5.7908325,8,8C8,10.2091064,9.7908325,12,12,12z",
      "fill": _vm.color || '#FFF'
    }
  }), _c('path', {
    attrs: {
      "d": "M13,13h-2c-3.9375043,0-7,2.1015015-7,4v2c0,0.5522842,0.4477153,1,1,1h14c0.5522842,0,1-0.4477158,1-1v-2  C20,15.1015015,17,13,13,13z",
      "fill": _vm.color || '#FFF'
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }