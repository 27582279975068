//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { YOUTUBE_NATIVE } from '@happstv/shared/util/multicastingConstants'

import PlatformIcon from '@/components/connectedAccounts/PlatformIcon.vue'

export default {
  name: 'MediaKitAudienceDemographics',
  components: {
    PlatformIcon,
  },
  props: {
    demographicsAccountList: Array, // each item has two keys: "account" and "demographics"
    onLight: Boolean,
    allowNoSelection: Boolean,
  },
  data() {
    const { demographicsAccountList = [], allowNoSelection } = this

    return {
      YOUTUBE_NATIVE,

      dimensions: { height: 0 },

      selectedUeid: allowNoSelection ? undefined : demographicsAccountList[0]?.account?.accountUeid,
    }
  },
  computed: {
    selectedDemographics() {
      const { demographicsAccountList, selectedUeid } = this
      if (!selectedUeid) return undefined

      const { demographics } = demographicsAccountList.find(({ account }) => account.accountUeid === selectedUeid) || {}
      return demographics
    },

    ageChartProps() {
      const { selectedDemographics = {} } = this
      const { age } = selectedDemographics
      if (!age) return undefined

      const { fractionByAgeGroup = {} } = age
      if (!Object.keys(fractionByAgeGroup).length) return undefined

      const sortedTopFiveAgeGroups = Object
        .entries(fractionByAgeGroup)
        .map(([ageGroup, fraction]) => ({ ageGroup, fraction }))
        .sort((a, b) => b.fraction - a.fraction)
        .slice(0, 5)

      return {
        options: { 
          chart: {
            type: 'bar',
            toolbar: { show: false },
            animations: {
              enabled: false,
            },
          },
          colors: ['#8F00FF'],
          xaxis: {
            categories: sortedTopFiveAgeGroups.map(age => age.ageGroup),
            labels: {
              formatter: v => `${(Number(v.toFixed(1)) * 100)}%`,
              style: {
                colors: ['#7D7E88'],
              },
            },
          },
          yaxis: {
            min: 0,
            max: 1,
            stepSize: 0.2,
            labels: {
              style: {
                colors: ['#7D7E88'],
              },
            },
          },
          grid: {
            xaxis: { lines: { show: false } },
            yaxis: { lines: { show: false } },
          },
          dataLabels: {
            enabled: true,
            formatter: v => `${(v * 100).toFixed(1)}%`,
            style: {
              colors: ['#8F00FF'],
            },
            dropShadow: {
              enabled: false,
            },
            textAnchor: 'left',
            offsetX: 18,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          tooltip: { enabled: false },
        },
        series: [{
          data: sortedTopFiveAgeGroups.map(age => age.fraction),
        }],
      }
    },
    countryChartProps() {
      const { selectedDemographics = {} } = this
      const { country } = selectedDemographics
      if (!country) return undefined

      const { fractionByCountry = {} } = country
      if (!Object.keys(fractionByCountry).length) return undefined

      const sortedTopFiveCountries = Object
        .entries(fractionByCountry)
        .map(([code, fraction]) => ({ code, fraction }))
        .sort((a, b) => b.fraction - a.fraction)
        .slice(0, 5)

      return {
        options: { 
          chart: {
            type: 'bar',
            toolbar: { show: false },
            animations: {
              enabled: false,
            },
          },
          colors: ['#0085FF'],
          xaxis: {
            categories: sortedTopFiveCountries.map(country => country.code),
            labels: {
              formatter: v => `${(Number(v.toFixed(1)) * 100)}%`,
              style: {
                colors: ['#7D7E88'],
              },
            },
          },
          yaxis: {
            min: 0,
            max: 1,
            stepSize: 0.2,
            labels: {
              style: {
                colors: ['#7D7E88'],
              },
            },
          },
          grid: {
            xaxis: { lines: { show: false } },
            yaxis: { lines: { show: false } },
          },
          dataLabels: {
            enabled: true,
            formatter: v => `${(v * 100).toFixed(1)}%`,
            style: {
              colors: ['#0085FF'],
            },
            dropShadow: {
              enabled: false,
            },
            textAnchor: 'left',
            offsetX: 18,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          tooltip: { enabled: false },
        },
        series: [{
          data: sortedTopFiveCountries.map(country => country.fraction),
        }],
      }
    },
    genderChartProps() {
      const { selectedDemographics = {}, onLight } = this
      const { gender } = selectedDemographics
      if (!gender) return undefined

      const { fractionFemale } = gender
      if (!fractionFemale) return undefined

      const fractionMale = 1 - fractionFemale

      return {
        options: {
          chart: {
            type: 'donut',
            animations: {
              enabled: false,
            },
          },
          labels: ['Female', 'Male'],
          tooltip: { enabled: false },
          colors: ['#8F00FF', '#0085FF'],
          stroke:{
            colors: [onLight ? '#FFF' : '#121316'],
            width: 5,
          },
          legend: {
            labels: {
              colors: ['#7D7E88'],
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#8F00FF', '#0085FF'],
              fontSize: '15px',
            },
            dropShadow: {
              enabled: false,
            },
          },
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 35,
              },
              customScale: 0.8,
            },
          },
        },
        series: [fractionFemale, fractionMale],
      }
    },
  },
}
