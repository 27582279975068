var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "publicCreatorList",
    class: {
      isMobile: _vm.isMobile
    }
  }, [!_vm.creatorList || !_vm.mediaKitList ? _c('LoadingSpinner', {
    staticClass: "absolute center",
    attrs: {
      "type": "greyscaleLight"
    }
  }) : [_vm.agencyData.agencyLogo ? _c('div', {
    staticClass: "agencyHeader"
  }, [_c('AgencyLogo', {
    attrs: {
      "agencyLogo": _vm.agencyData.agencyLogo
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "topBar"
  }, [_c('div', {
    staticClass: "listTitle"
  }, [_c('p', [_vm._v(_vm._s(_vm.creatorList.title))])]), _c('div', {
    staticClass: "listTotals themeListItem themeExtraPadding"
  }, [_c('div', [_c('p', [_vm._v("Creators")]), _vm.mediaKitList === undefined ? _c('LoadingSpinner', {
    staticStyle: {
      "height": "20px",
      "width": "20px"
    },
    attrs: {
      "type": "greyscaleDark"
    }
  }) : _c('p', [_vm._v(_vm._s(_vm.mediaKitList.length))])], 1), _c('div', [_c('p', [_vm._v("Total Audience")]), _c('div', {
    staticClass: "totalFollowers"
  }, _vm._l(Object.entries(_vm.aggregatedFollowersByPlatform), function (_ref, i) {
    var platform = _ref[0],
        followerCount = _ref[1];
    return _c('div', {
      key: i,
      staticClass: "totalFollowerPlatform"
    }, [_vm.getPlatformIcon(platform) ? _c(_vm.getPlatformIcon(platform), {
      tag: "component",
      staticClass: "platformIcon",
      attrs: {
        "color": "#000"
      }
    }) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.numberMagnitudeString(followerCount)))])], 1);
  }), 0)])])]), _c('div', {
    staticClass: "creatorItemList"
  }, _vm._l(_vm.mediaKitList, function (mediaKit, i) {
    return _c('PublicMediaKitPreview', _vm._b({
      key: i,
      staticClass: "clickable",
      nativeOn: {
        "click": function click($event) {
          return _vm.openMediaKit(mediaKit);
        }
      }
    }, 'PublicMediaKitPreview', {
      mediaKit: mediaKit,
      isMobile: _vm.isMobile,
      hideRates: _vm.hideRates,
      creatorList: _vm.creatorList
    }, false));
  }), 1), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }), _c('div', {
    staticClass: "poweredBy"
  }, [_c('p', [_vm._v("Powered by")]), _c('ReachLogo', {
    staticClass: "reachLogo",
    attrs: {
      "title": "Reach",
      "alt": "Reach",
      "color": "#000"
    }
  })], 1)], _c('transition', {
    attrs: {
      "appear": "",
      "duration": 300
    }
  }, [_vm.mediaKitPreview ? _c('PopupLayer', {
    key: "mediaKitOverlay",
    staticClass: "popupLayer fillScreen mediaKitOverlay",
    attrs: {
      "component": _vm.PublicMediaKitFrame,
      "params": {
        mediaKit: _vm.mediaKitPreview
      },
      "popupType": "dialog"
    },
    on: {
      "close": _vm.closeMediaKit
    }
  }) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }