var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "agencyCreatorAudienceDemographics",
    style: {
      height: "".concat(_vm.dimensions.height, "px")
    }
  }, [_c('div', {
    directives: [{
      name: "observe-dimensions",
      rawName: "v-observe-dimensions",
      value: _vm.dimensions,
      expression: "dimensions"
    }]
  }, [_c('div', {
    attrs: {
      "id": "demographicAccountList"
    }
  }, [_c('p', [_vm._v("Select an account:")]), _vm._l(_vm.demographicsAccountList, function (_ref) {
    var account = _ref.account;
    return _c('PillButton', {
      key: account.accountUeid,
      class: {
        selected: _vm.selectedUeid == account.accountUeid
      },
      attrs: {
        "themeListItem": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          _vm.selectedUeid = _vm.selectedUeid == account.accountUeid && _vm.allowNoSelection ? undefined : account.accountUeid;
        }
      }
    }, [_c('PlatformIcon', {
      attrs: {
        "platformId": account.platform,
        "color": _vm.onLight ? '#000' : '#FFF'
      }
    }), account.platform === _vm.YOUTUBE_NATIVE && account.displayName ? _c('p', [_vm._v(_vm._s(account.displayName))]) : account.username ? _c('p', [_vm._v("@" + _vm._s(account.username))]) : account.displayName ? _c('p', [_vm._v(_vm._s(account.displayName))]) : _vm._e()], 1);
  })], 2), _vm.selectedUeid ? _c('div', {
    attrs: {
      "id": "demographicsList"
    }
  }, [_vm.selectedDemographics.gender && _vm.genderChartProps ? _c('div', {
    staticClass: "demographicItem"
  }, [_c('h6', [_vm._v("Gender")]), _c('apexchart', _vm._b({
    attrs: {
      "width": "300",
      "height": "150"
    }
  }, 'apexchart', _vm.genderChartProps, false))], 1) : _vm._e(), _vm.selectedDemographics.country && _vm.countryChartProps ? _c('div', {
    staticClass: "demographicItem"
  }, [_c('h6', [_vm._v("Country")]), _c('apexchart', _vm._b({
    attrs: {
      "width": "300",
      "height": "150"
    }
  }, 'apexchart', _vm.countryChartProps, false))], 1) : _vm._e(), _vm.selectedDemographics.age && _vm.ageChartProps ? _c('div', {
    staticClass: "demographicItem"
  }, [_c('h6', [_vm._v("Age")]), _c('apexchart', _vm._b({
    attrs: {
      "width": "300",
      "height": "150"
    }
  }, 'apexchart', _vm.ageChartProps, false))], 1) : _vm._e()]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }