//
//
//
//

export default {
  name: 'AgencyLogo',
  props: {
    onDark: Boolean,
    agencyLogo: Object, // { onDarkPath, onLightPath }
    height: {
      type: Number,
      default: 30,
    },
    disableInvert: Boolean,
  },
  computed: {
    properties() {
      const { onDark, disableInvert, agencyLogo = {} } = this
      const { onDarkPath, onLightPath } = agencyLogo

      if (onDark) {
        if (onDarkPath) {
          return { path: onDarkPath }
        }

        if (!disableInvert && onLightPath) {
          return { path: onLightPath, invert: true }
        }
      } else {
        if (onLightPath) {
          return { path: onLightPath }
        }

        if (!disableInvert && onDarkPath) {
          return { path: onDarkPath, invert: true }
        }
      }

      return {}
    },
  },
}
