//
//
//
//
//
//
//
//

/* This component just turns newline characters into html <br> tags. It's safer than just using the 'v-html' attribute, because that's exposed to any javascript that's in the string. */
export default {
  name: 'MultilineText',
  props: {
    tag: {
      type: String,
      default: 'p',
    },
  },
  data() {
    return {
      text: '',
      textInterval: undefined,
    }
  },
  computed: {
    lineList() {
      return this.text.split('\n')
    },
  },
  methods: {
    updateText() {
      const { text = '' } = this.$slots.default?.[0] || {}
      this.text = text
    },
  },
  mounted() {
    // $slots isn't reactive, so we have to continuously check it
    this.updateText()
    this.textInterval = setInterval(this.updateText, 500)
  },
  beforeDestroy() {
    clearInterval(this.textInterval)
  },
}
