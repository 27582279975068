//
//
//
//
//
//
//
//
//
//

import { makeReachUrl } from '@happstv/shared/util/makeReachUrl'
import BrandFooter from '@/components/universal/navigation/BrandFooter.vue'

export default {
  name: 'PublicPageFrame',
  components: {
    BrandFooter,
  },
  props: {
    showFooter: {
      type: Boolean,
      default: true,
    },
    backgroundPath: String,
    loading: Boolean,
  },
  data() {
    return {
      makeReachUrl,
      dimensions: { width: 0, height: 0 },
    }
  },
  computed: {
    columnWidth() {
      return Math.min(400, this.dimensions.width - 60)
    },
    backgroundStyle() {
      const { backgroundPath } = this
      if (!backgroundPath) return {}
      return { backgroundImage: `url(${backgroundPath})` }
    },
  },
}
